<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <template v-if="!loadingLandingPage && business !== null">
      <div class="row">
        <div class="col py-4">
          <div class="px-4">
            <div v-html="business.review_gate_settings.sites_body_text"></div>
          </div>
          <div class="grid-container align-items-center">
            <div v-for="(site,i) in business.review_sites" :key="i">
              <a :href="site.url" target="_blank">
                <img v-if="imgSrc(site) !== null" :src="imgSrc(site)" /></a>
                <br />
                <span v-if="site.hasOwnProperty('label') && site.label !== ''" class="site-label">
                  {{site.label}}
                </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  components:{

  },
  props:{
    business: {
      type: Object,
      default:null
    }
  },
  data() {
    return {
      loadingLandingPage: false,
      dataBusiness:this.business,
      business_id:null
    }
  },
  metaInfo () {
    return {
      title: 'Review ' + this?.business.business_name,
      meta: [
        { name: 'og:title', content: 'Review ' + this?.business.business_name },
      ],

    }
  },
  computed: {

  },

  mounted() {
    // If we are dealing with a card, set a custom business ID we will parse in the API
    if(this.$route.name === 'gate.short-review' && !this.business.review_gate_settings.hasOwnProperty('sites_body_text')){
      this.$set(this.business.review_gate_settings, 'sites_body_text', '<p style="text-align: center;"><strong><span style="font-size: 14pt;">Leave Us A Review!</span></strong></p>\n' +
          '<p style="text-align: center;"><span style="font-size: 14pt;">Thanks for stopping by!</span></p>\n' +
          '<p style="text-align: center;"><span style="font-size: 14pt;">We\'d love it if you could leave us a review on one of the sites below!</span></p>');
    }
  },
  methods: {
    imgSrc(site){
      let src = null;
      if(site.type === 'google' && (!site.hasOwnProperty('logo_path') || site.logo_path === '' || site.logo_path === 'null')){
          src = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/google-icon-100.png';
      }else if(site.type === 'facebook' && (!site.hasOwnProperty('logo_path') || site.logo_path === '' || site.logo_path === 'null')){
        src = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/facebook-100.png';
      }else if(site.type === 'yelp' && (!site.hasOwnProperty('logo_path') || site.logo_path === '' || site.logo_path === 'null')){
        src = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/yelp-logo-100.png';
      }else if(site.logo_path && site.logo_path !== '' && site.logo_path !== 'null'){
        src = this.business.cdn_url + site.logo_path;
      }
      return src;
    },
    getWidget() {
      this.loadingLandingPage = true;
      this.$store
          .dispatch('landingPage/getReviewGate', this.business_id)
          .then((res) => {
            this.dataBusiness = res.reviewGate;
            if(!this.dataBusiness.business.review_gate_enabled){
              window.location.href = this.reviewLink;
            }else{
              this.status = 200;
              this.loadingLandingPage = false

            }
          })
          .catch((error) => {
            if(error.response.status){
              this.status = error.response.status;
              document.body.classList.add('gate-404');
            }
            this.loadingLandingPage = false
          })
    },
  },
}
</script>
<style>
@import "https://fonts.googleapis.com/css2?family=Raleway&display=swap";
</style>
<style lang="scss" scoped>
.grid-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 30px;
  grid-template-columns: repeat(4, auto);
  border-collapse: collapse;
  > div{
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 15px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
    img{
      margin-bottom: 10px;
      max-width: 100px;
      height: auto;
    }
    span.site-label{
      margin-top: 10px;
      font-weight: bold;
      font-size: 20px;
    }
  }
}
@media screen and (max-width:767px) {
  .grid-container {
    grid-template-columns: repeat(2, auto);
    display: grid;
  }
}
</style>
